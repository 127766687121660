import fixture from 'can-fixture';

fixture('GET /api/games_pro', () => {
	let v = new Date().getTime();
	return [
		{
			id: 46,
			title: 'CutieKitty',
			thumb_image: '/data/games/cutiekitty_thumbnail.png',
			title_image: '/data/games/cutiekitty_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/cutiekitty' + '/index.html?v=' + v,
		},
		{
			id: 45,
			title: '9 Pots of Fortune',
			thumb_image: '/data/games/ninepots_thumbnail.png',
			title_image: '/data/games/ninepots_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/ninepots' + '/index.html?v=' + v,
		},
		{
			id: 44,
			title: 'Siberian Tiger',
			thumb_image: '/data/games/siberian_thumbnail.png',
			title_image: '/data/games/siberian_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/siberiantiger' + '/index.html?v=' + v,
		},
		{
			id: 43,
			title: 'Hero of Asgard',
			thumb_image: '/data/games/asgard_thumbnail.png',
			title_image: '/data/games/asgard_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/heroofasgard' + '/index.html?v=' + v,
		},
		{
			id: 42,
			title: '88 Twins Fortune',
			thumb_image: '/data/games/88twins_thumbnail.png',
			title_image: '/data/games/88twins_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/twinsfortunes' + '/index.html?v=' + v,
		},
		{
			id: 41,
			title: 'Pirate Riches',
			thumb_image: '/data/games/piraterich_thumbnail.png',
			title_image: '/data/games/piraterich_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/pirateriches' + '/index.html?v=' + v,
		},
		{
			id: 40,
			title: 'Peter Pan',
			thumb_image: '/data/games/peterpan_thumbnail.png',
			title_image: '/data/games/peterpan_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/peterpan' + '/index.html?v=' + v,
		},
		{
			id: 39,
			title: 'Wild Rhino Deluxe',
			thumb_image: '/data/games/rhino_thumbnail.png',
			title_image: '/data/games/rhino_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/wildrhinodeluxe' + '/index.html?v=' + v,
		},
		{
			id: 38,
			title: 'Trick or Treat',
			thumb_image: '/data/games/trick_thumbnail.png',
			title_image: '/data/games/trick_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/trickortreat' + '/index.html?v=' + v,
		},
		{
			id: 37,
			title: 'War Against The Devil',
			thumb_image: '/data/games/devil_thumbnail.png',
			title_image: '/data/games/devil_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/warthedevil' + '/index.html?v=' + v,
		},
		{
			id: 36,
			title: 'Gold Rush',
			thumb_image: '/data/games/gold_thumbnail.png?v=1',
			title_image: '/data/games/gold_title.png?v=1',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/goldrush' + '/index.html?v=' + v,
		},
		{
			id: 34,
			title: 'VooDoo Spell',
			thumb_image: '/data/games/voodoospell_thumbnail.png',
			title_image: '/data/games/voodoospell_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/voodoospell' + '/index.html?v=' + v,
		},
		{
			id: 35,
			title: 'Happy Circus',
			thumb_image: '/data/games/happycircus_thumbnail.png',
			title_image: '/data/games/happycircus_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/happycircus' + '/index.html?v=' + v,
		},
		{
			id: 26,
			title: 'Attack of The Kraken',
			thumb_image: '/data/games/kraken_thumbnail.png',
			title_image: '/data/games/kraken_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/attackofthekraken' + '/index.html?v=' + v,
		},
		{
			id: 30,
			title: 'Farm Story',
			thumb_image: '/data/games/farmstory_thumbnail.png',
			title_image: '/data/games/farmstory_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/farmstory' + '/index.html?v=' + v,
		},
		{
			id: 22,
			title: 'Burning Flames Hold and Spin',
			thumb_image: '/data/games/burningflames_thumbnail.png',
			title_image: '/data/games/burningflames_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/burningflames' + '/index.html?v=' + v,
		},
		{
			id: 21,
			title: 'Princess of Sakura',
			thumb_image: '/data/games/sakurafortune_thumbnail.png',
			title_image: '/data/games/sakurafortune_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/sakurafortune' + '/index.html?v=' + v,
		},
		{
			id: 20,
			title: 'Temple of Olympus',
			thumb_image: '/data/games/templeofolympus_thumbnail.png',
			title_image: '/data/games/templeofolympus_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/templeofolympus' + '/index.html?v=' + v,
		},
		{
			id: 19,
			title: 'Super Juicy',
			thumb_image: '/data/games/superjuicy_thumbnail.png',
			title_image: '/data/games/superjuicy_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/superjuicy' + '/index.html?v=' + v,
		},
		{
			id: 17,
			title: 'Panda`s Luck',
			thumb_image: '/data/games/panda_thumbnail.png',
			title_image: '/data/games/panda_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/panda' + '/index.html?v=' + v,
		},
		{
			id: 18,
			title: 'Chicken Farm',
			thumb_image: '/data/games/chicken_thumbnail.png',
			title_image: '/data/games/chicken_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/chickenfarm' + '/index.html?v=' + v,
		},
		{
			id: 13,
			title: 'Maya Civilization (3D)',
			thumb_image: '/data/games/maya_thumbnail.png',
			title_image: '/data/games/maya_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/mayacivilization' + '/index.html?v=' + v,
		},
		{
			id: 15,
			title: 'Savannah',
			thumb_image: '/data/games/savannah_thumbnail.png',
			title_image: '/data/games/savannah_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/savannah' + '/index.html?v=' + v,
		},
		{
			id: 16,
			title: 'Shaolins-Tiger',
			thumb_image: '/data/games/shaolins_thumbnail.png',
			title_image: '/data/games/shaolins_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/shaolinstiger' + '/index.html?v=' + v,
		},
		{
			id: 1,
			title: 'Wild West Saloon',
			thumb_image: '/data/games/saloon_thumbnail.png',
			title_image: '/data/games/saloon_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/wildsaloon' + '/index.html?v=' + v,
		},
		{
			id: 2,
			title: 'Assassin Ninja',
			thumb_image: '/data/games/ninja_thumbnail.png',
			title_image: '/data/games/ninja_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/ninja' + '/index.html?v=' + v,
		},
		{
			id: 3,
			title: 'Chicago City',
			thumb_image: '/data/games/chicago_thumbnail.png',
			title_image: '/data/games/chicago_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/chicago' + '/index.html?v=' + v,
		},
		{
			id: 4,
			title: 'Super Phoenix',
			thumb_image: '/data/games/phoenix_thumbnail.png',
			title_image: '/data/games/phoenix_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/phoenix' + '/index.html?v=' + v,
		},
		{
			id: 5,
			title: 'Zeppelin',
			thumb_image: '/data/games/zeppelin_thumbnail.png',
			title_image: '/data/games/zeppelin_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/zeppelin' + '/index.html?v=' + v,
		},
		{
			id: 6,
			title: 'Squid of death',
			thumb_image: '/data/games/squid_thumbnail.png',
			title_image: '/data/games/squid_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/squid' + '/index.html?v=' + v,
		},
		{
			id: 7,
			title: 'Pirates Treasure',
			thumb_image: '/data/games/pirates_thumbnail.png',
			title_image: '/data/games/pirates_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/piratetreasure' + '/index.html?v=' + v,
		},
		{
			id: 8,
			title: 'Book of Ra',
			thumb_image: '/data/games/book_thumbnail.png',
			title_image: '/data/games/book_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/bookofra' + '/index.html?v=' + v,
		},
		{
			id: 9,
			title: 'Mighty Thor',
			thumb_image: '/data/games/thor_thumbnail.png',
			title_image: '/data/games/thor_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/thor' + '/index.html?v=' + v,
		},
		{
			id: 10,
			title: 'Bonanza',
			thumb_image: '/data/games/bonanza_thumbnail.png',
			title_image: '/data/games/bonanza_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/bonanza' + '/index.html?v=' + v,
		},
		{
			id: 23,
			title: 'Cats Paw',
			thumb_image: '/data/games/catspaw_thumbnail.png',
			title_image: '/data/games/catspaw_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/catspaws' + '/index.html?v=' + v,
		},
		{
			id: 24,
			title: 'Little Red Cape',
			thumb_image: '/data/games/redhood_thumbnail.png',
			title_image: '/data/games/redhood_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/littleredcape' + '/index.html?v=' + v,
		},
		{
			id: 25,
			title: 'Trees of Fortune',
			thumb_image: '/data/games/trees_thumbnail.png',
			title_image: '/data/games/tree_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/treeoffortune' + '/index.html?v=' + v,
		},
		{
			id: 27,
			title: 'Around The World',
			thumb_image: '/data/games/aroundworld_thumbnail.png',
			title_image: '/data/games/aroundworld_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/aroundtheworld' + '/index.html?v=' + v,
		},
		{
			id: 28,
			title: 'Wild West Cowboy2',
			thumb_image: '/data/games/wwc2_thumbnail.png',
			title_image: '/data/games/wwc2_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/wildwestgold' + '/index.html?v=' + v,
		},
		{
			id: 29,
			title: 'Lamp of Genie',
			thumb_image: '/data/games/genie_thumbnail.png',
			title_image: '/data/games/genie_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/aladdin' + '/index.html?v=' + v,
		},
		{
			id: 31,
			title: 'Chicago2',
			thumb_image: '/data/games/chicago2_thumbnail.png',
			title_image: '/data/games/chicago2_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/chicago2' + '/index.html?v=' + v,
		},
		{
			id: 32,
			title: 'White Dragon',
			thumb_image: '/data/games/whitedragon_thumbnail.png',
			title_image: '/data/games/whitedragon_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/whitedragon' + '/index.html?v=' + v,
		},
		{
			id: 33,
			title: 'ZEUS STRIKES',
			thumb_image: '/data/games/zeusstrike_thumbnail.png',
			title_image: '/data/games/zeusstrike_title.png',
			launcher_url: process.env.VUE_APP_LAUNCHER_URL + '/zeus' + '/index.html?v=' + v,
		},
	];
});

fixture('GET /api/games/more/{page}', function (request) {
	if (2 < parseInt(request.data.page)) {
		return [];
	}

	return [];
});

export default fixture;
