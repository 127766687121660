
import { ref, defineComponent } from 'vue';
import '../mocks/games';
import '../mocks/games_pro';
import axios from 'axios';
import Games from '@/models/Games';
import { useI18n } from 'vue-i18n';
import { api as fullscreen } from 'vue-fullscreen';

const apiServer = process.env.VUE_APP_API_URL;

const fullscreenWrapper = ref<HTMLDivElement>();
const iosDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

export default defineComponent({
	name: 'Games',
	data() {
		return {
			page: 1,
			games: [] as Games[],
			launcherOpened: false,
			game: {} as Partial<Games>,
			searchPlaceholder: this.t('games.search_placeholder'),
			searchQuery: '',
			searchGames: [] as Games[],
			fullscreen: false,
			teleport: true,
		};
	},
	computed: {
		filteredGames(): Games[] {
			if (this.searchQuery) {
				return this.games.filter((item) => {
					return this.searchQuery
						.toLowerCase()
						.split(' ')
						.every((v) => item.title.toLowerCase().includes(v));
				});
			} else {
				return this.games;
			}
		},
		landscape(): boolean {
			return window.innerWidth > window.innerHeight;
		},
		ios(): boolean {
			return iosDevices.includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
		},
	},
	mounted() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		async getList(): Promise<void> {
			let apiUrl = apiServer + '/api/games';
			if (process.env.NODE_ENV == 'production') {
				apiUrl = apiServer + '/api/games_pro';
			}

			const response = await axios.get(apiUrl);
			this.games = response.data;

			this.games.forEach((game, index) => {
				if (game.launcher_url == '') {
					this.games[index].launch_text = this.t('games.coming_soon');
				} else {
					this.games[index].launch_text = this.t('games.play_button');
				}
			});
			this.page = 1;
		},
		async getMore(): Promise<void> {
			const response = await axios.get(apiServer + '/api/games/more/' + (this.page + 1));
			this.games = [...this.games, ...response.data];

			this.games.forEach((game, index) => {
				if (game.launcher_url == '') {
					this.games[index].launch_text = this.t('games.coming_soon');
				} else {
					this.games[index].launch_text = this.t('games.play_button');
				}
			});
			this.page++;
		},
		launchGame(id: string): void {
			let findGame = this.games.find((game) => game.id == id);

			if (findGame) {
				if (findGame.launcher_url !== '') {
					this.game = findGame;
					this.launcherOpened = true;
				}
			}
		},
		closeLauncher(): void {
			this.launcherOpened = false;
			if (fullscreen.isFullscreen) {
				this.toggle();
			}
			this.fullscreen = false;
		},
		async toggle() {
			await fullscreen.toggle(fullscreenWrapper.value, {
				teleport: this.teleport,
				callback: (isFullscreen) => {
					this.fullscreen = isFullscreen;
				},
			});
			this.fullscreen = fullscreen.isFullscreen;
		},
		handleResize() {
			this.landscape = window.innerWidth > window.innerHeight;
			console.log('this.landscape', this.landscape);
		},
	},
	async created(): Promise<void> {
		await this.getList();
	},
	setup() {
		const { t } = useI18n({
			userScope: 'global',
			inheritLocale: true,
		});
		return { t };
	},
});
